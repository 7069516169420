import './index.scss';

export const LeaderboardBanner = () => (
        <div className="leaderboard-banner">
            {/* <h1>Affiliate Leaderboard</h1> */}
            <div id="leaderboard-banner-title-img">
                <img src='./assets/xmasstitle.png' alt='Leaderboard banner' />
            </div>
            <div className='promotion'>
                <span>Legends that used code </span>
                <span className='code'>BUDI</span>
                <span>on</span>
                <span><img src="./assets/logo.png" alt='CSGORoll logo'/></span>
            </div>
            <h3>01.12.2024 - 31.12.2024.</h3>
            <span className="thankyou">Thank you so much for your support ❤️</span>
        </div>
)