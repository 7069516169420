import { Link } from "react-router-dom";
import { LeaderboardBanner } from "./banner/Leaderboard-Banner";
import './index.scss';
import { LeaderboardResults } from "./results/Leaderboard-Results";
import { LeaderboardRewards } from "./rewards/Leaderboard-Rewards";
import { useEffect, useState } from "react";




export const Leaderboard = () => {
    const [total, setTotal] = useState<number>(0);
    useEffect(() => {
        import(`./results/data/december.json`).then((module) => {
            const data = module.default;
            const total = data.filter(n => !n.statuses.includes('STOLEN')).reduce((acc: number, curr: Record<string, any>) => {
                return acc + parseFloat(curr.amount);
            }, 0);
            setTotal(total);
        })
    }, []);
    return         <div className="leaderboard">
            <LeaderboardBanner />
            <LeaderboardRewards  totalDeposit={total} lastMonthTotalDeposit={17660*10*2}/>
            <Link to="/leaderboard_november">See previous leaderboard results</Link>
            <LeaderboardResults  dataSource="december" title="December" timestamp="14.12.2024. at 13:45 CEST"/>
        </div>
}